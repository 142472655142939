 .bg-gradient-submit {
   background: rgb(0,51,102) repeat-x !important;
   color: white !important;
 }
 
 .bg-gradient-submit-register {
   background: rgb(54,96,172) repeat-x !important;
   color: white !important;
 }

  .bg-gradient-submit-pdf {
   background:#3490dc !important;
   color: white !important;
 }

 .bg-gradient-submit-report {
  background: #ffc04d !important;
  color: white !important;
}
 
.drop-area {
  color: gray;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
  border: 5px solid gray;
  border-radius: 15px;
}

.drop-area:hover {
  background-color: #bde9ba;
}

.enter-drop-area {
  background-color: #bde9ba;
  cursor: grab;
}

.additional-file-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}