.drop-area {
  color: gray;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
  border: 5px solid gray;
  border-radius: 15px;
}

.drop-area:hover {
  background-color: #bde9ba;
}

.enter-drop-area {
  background-color: #bde9ba;
  cursor: grab;
}

.additional-file-icon {
  font-size: 10em;
  color: green;
}

.additional-file-flex {
  display: flex;
  align-items: center;
}

.additional-file-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  font-size: 10px;
}

.additional-file-flex-icon-block {
  position: relative;
}

.additional-file-delete-mark {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 3em;
  color: red;
  background-color: rgb(241, 241, 241);
  border-radius: 50px;
  text-align: center;
}