.vs__search, .vs__search:focus {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	line-height: 1.4;
	font-size: 1em;
	border: 1px solid transparent;
	border-left: none;
	outline: none;
	margin: 4px 0 0 0;
	padding: 0 7px;
	background: white;
	box-shadow: none;
	width: 0;
	max-width: 100%;
	flex-grow: 1;
}

.v-select, .v-select * {
	box-sizing: border-box;
} 