$enable-gradients: true;
$enable-shadows: true;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "vue-select/src/scss/vue-select.scss";
@import "./vue-select"; 

@import 'additional-file.scss';
@import 'character-decoration.scss';
@import 'common.scss';
@import 'input.scss';
